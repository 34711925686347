import { z } from 'zod';

// Must assign NEXT_PUBLIC_* env vars to a variable to force Next to inline them
const publicEnvObj = {
  NEXT_PUBLIC_SENTRY_ORG: process.env.NEXT_PUBLIC_SENTRY_ORG,
  NEXT_PUBLIC_SENTRY_PROJECT: process.env.NEXT_PUBLIC_SENTRY_PROJECT,
  NEXT_PUBLIC_SENTRY_URL: process.env.NEXT_PUBLIC_SENTRY_URL,
  NEXT_PUBLIC_SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,

  NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN,
  NEXT_PUBLIC_APP_TITLE: process.env.NEXT_PUBLIC_APP_TITLE,
  NEXT_PUBLIC_APP_URI: process.env.NEXT_PUBLIC_APP_URI,
  NEXT_PUBLIC_API_URI: process.env.NEXT_PUBLIC_API_URI,
  NEXT_PUBLIC_GOSHOP_API_URI: process.env.NEXT_PUBLIC_GOSHOP_API_URI,

  NEXT_PUBLIC_ENABLE_MOBILE_CONSOLE:
    process.env.NEXT_PUBLIC_ENABLE_MOBILE_CONSOLE,

  NEXT_PUBLIC_GTM_KEY: process.env.NEXT_PUBLIC_GTM_KEY,
  NEXT_PUBLIC_ENABLE_TRACKING_EVENT:
    process.env.NEXT_PUBLIC_ENABLE_TRACKING_EVENT,

  NEXT_PUBLIC_GOOGLE_RECAPCHA_SITE_KEY:
    process.env.NEXT_PUBLIC_GOOGLE_RECAPCHA_SITE_KEY,

  NEXT_PUBLIC_XOR_KEY: process.env.NEXT_PUBLIC_XOR_KEY,

  NEXT_PUBLIC_EP_API_URI: process.env.NEXT_PUBLIC_EP_API_URI,
  NEXT_PUBLIC_EP_API_KEY: process.env.NEXT_PUBLIC_EP_API_KEY,
};

export const publicEnv = z
  .object({
    NEXT_PUBLIC_SENTRY_ORG: z.string(),
    NEXT_PUBLIC_SENTRY_PROJECT: z.string(),
    NEXT_PUBLIC_SENTRY_URL: z.string(),
    NEXT_PUBLIC_SENTRY_RELEASE: z.string().optional(),
    NEXT_PUBLIC_SENTRY_DSN: z.string(),

    NEXT_PUBLIC_DOMAIN: z.string(),
    NEXT_PUBLIC_APP_TITLE: z.string(),
    NEXT_PUBLIC_APP_URI: z.string(),
    NEXT_PUBLIC_API_URI: z.string(),
    NEXT_PUBLIC_GOSHOP_API_URI: z.string(),

    NEXT_PUBLIC_ENABLE_MOBILE_CONSOLE: z.string().optional(),

    NEXT_PUBLIC_GTM_KEY: z.string().optional(),
    NEXT_PUBLIC_ENABLE_TRACKING_EVENT: z.string(),

    NEXT_PUBLIC_GOOGLE_RECAPCHA_SITE_KEY: z.string(),

    NEXT_PUBLIC_XOR_KEY: z.string(),
    NEXT_PUBLIC_EP_API_URI: z.string(),
    NEXT_PUBLIC_EP_API_KEY: z.string(),
  })
  .parse(publicEnvObj);
